@import (less) "mixins.less";

/*************FORMS************/
@content: #666;
@labelColor: #666;
@inputColor: #bfbfbf;
@inputBG: #fff;
@inputBorder: @content;
@inputFocusBorder: #666;
@inputFocusBG: #fff;
@errorBG: #bfbfbf;
@errorBorder: @red;
@errorLabel: @aqua;

.gform_wrapper{
            .bodyfont()!important;
            width:100%!important;
            margin-top:0!important;
            label{
                .poppins(20px)!important;
                .uppercase();
                font-weight: 600!important;
                display:block;
                color:@red;
                }
            .ginput_complex label{
                font-size:12px!important;
                }
            input[type=text], textarea{
                background:transparent;
                border:1px solid @gray;
                padding:12px 12px 12px 36px!important;
                width:100%!important;
                color:@blue;
               .work(20px)!important;
               outline:none;
               .transition();
                &::-webkit-input-placeholder {
                       color: @blue;
                    }
                    
                    &:-moz-placeholder { /* Firefox 18- */
                        color: @blue;
                    }
                    
                    &::-moz-placeholder {  /* Firefox 19+ */
                       color: @blue;
                    }
                    
                    &:-ms-input-placeholder {  
                        color: @blue;
                    }
                }
            input[type=text]:focus, textarea:focus{
                background:@grayLight;
                border:1px solid @blue;
                }
            
            select{
                width:100%!important;
                }
            ul.gform_fields{
                padding:0px 0 10px 0!important;
                margin:0!important;
                >li{
                    padding-left:0!important;
                    padding-bottom:3px!important;
                    background:none!important;
                    margin-bottom:0!important;
                    &.gfield.gf_left_half{width:48%!important;margin-right:2%!important;}
                    .gfield.gf_right_half{width:48%!important;margin-left:2%!important;}
                    .ginput_container{
                    	position: relative;
                    	>i{
                    		color:@aqua;
                    		position:absolute;
                    		top:15px;
                    		left:10px;
                    		}
                    	}
                  &.no-icon{
                  	 input[type=text], textarea{
                  	 	padding:12px 12px 12px 12px!important;
                  	 	}
                  	}
                  >i{display: none!important;}
                    }
                >li.gfield_error{
                   border:none!important;
                    max-width: 100%!important;
                    label, .gfield_description{
                        color:@red!important;
                        
                        }
                    input,textarea{
                    	border: 1px solid @gray!important;
                    	&::-webkit-input-placeholder {
                       color: @red;
                    }
                    
                    &:-moz-placeholder { /* Firefox 18- */
                        color: @red;
                    }
                    
                    &::-moz-placeholder {  /* Firefox 19+ */
                       color: @red;
                    }
                    
                    &:-ms-input-placeholder {  
                        color: @red;
                    }
                    	}
                    .validation_message{
                    	.work(14px);
                    	font-weight: 300!important;
                    	color:@aqua!important;
                    	.italic();
                    	}
                    }
                }
            ul.gfield_checkbox, ul.gfield_radio{
            	padding-left:0!important;
            	li{
            		input[type=checkbox]:checked+label,input[type=radio]:checked+label{margin-left:6px;.bold()!important;}
            		>i{display: none;}
            		padding-left:0!important;
            		label{position: relative;top:1px;.transition();.poppins(18px)!important;font-weight:normal!important;text-transform: none;color:@content!important;}
            	}
            }
            .screen-reader-text{
            	display: none;
            	}
            .validation_message{
            	>ul{padding:0!important;}
            	}
            }
.validation_error{
    color:@errorLabel!important;
    border: none!important;
    text-align:left!important;
    .poppins(28px)!important;
    line-height: 1.3;
    }
.gform_footer{
    margin-top:0!important;
    padding:15px 0 0 0!important;

    input[type=submit]{
        
        &:hover{
            cursor:pointer;
            }
        }
    }
.gform_confirmation_message{
    .poppins(30px);
    color: @red;
    .uppercase();
    font-weight: 600;
    padding:20px 0 20px 0;
    line-height: 1.2;
    }
    
.gform_wrapper.two-column_wrapper {
	max-width: 100%;
}

.gform_wrapper.two-column_wrapper ul.gform_fields,
.gform_wrapper.two-column_wrapper ul.gform_column li.gsection:first-child {
	display: none;
}

.gform_wrapper.two-column_wrapper ul.gform_fields.gform_column {
	display: block;
	float:left;
}

.gform_wrapper.two-column_wrapper ul.one-half {
	margin-left: 6% !important;
	width: 47%;
}
.gform_wrapper.two-column_wrapper ul.one-half.first {
	margin-left: 0 !important;
}


.gform_wrapper .gsection {
	border-bottom: none !important;
}

.gform_wrapper .top_label li.gfield.gf_right_half+li.gsection {
	padding: 0 !important;
}

@media only screen and (max-width: 1075px) {
	
	.gform_wrapper.two-column_wrapper ul.one-half {
		width: 100%;
	}
	
	.gform_wrapper.two-column_wrapper ul.one-half {
		margin-left: 0 !important;
	}
	
	.gform_wrapper .gsection {
		display: none !important; /* remove !important if it's not needed */
	}
	
	#gform_wrapper_1 li {
		margin-bottom: 10px;
	}
	
}